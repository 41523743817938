
  import Infos from "../components/index/Infos.vue";
  import Hello from '../components/index/Hello.vue';
  import InfosTwo from "../components/index/InfosTwo.vue";
  import Marque from "../components/index/Marque.vue";
  import Ask from "../components/index/Ask.vue";
  import Connected from "../components/index/Connected.vue"

  export default {
    name: 'HomePage',
    components:{
        Hello,
        Infos,
        InfosTwo,
        Marque,
        Ask,
        Connected
    }
  }
  